.header__schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid black; */
}
.header-schedule-item {
  padding: 15px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
}

@media (max-width: 650px) {
  .header__schedule {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 15px;
  }
  .header-schedule-item {
    padding: 0px;
    padding-left: 0px;
    margin: 0;
    width: 100%;
    font-size: 13px;
  }
  .header-schedule-img {
    scale: 0.6;
  }
}
@media (max-width: 400px) {
  .header__schedule {
    margin-top: 35px;
  }
  .header-schedule-item {
    padding: 0px;
    padding-left: 0px;
    margin: 0;
    width: 100%;
    font-size: 13px;
  }
  .header-schedule-img {
    scale: 0.6;
  }
}
@media (max-width: 310px) {
  .header__schedule {
    padding-right: 8px;
  }
}
