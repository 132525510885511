.galarry-block_1,
.galarry-block_2,
.galarry-block_3,
.galarry-block_4,
.galarry-block_5 {
  padding: 30px;

  background-color: white;

  border-radius: 10px;
}

.fotoImgGallary {
  display: block;
  margin: 0 auto;
  width: 450px;
  height: 500px;
  object-fit: cover;
}
@media (max-width: 540px) {
  .fotoImgGallary {
    display: block;
    width: 100%;
    height: 300px;
    /* object-fit: cover; */
  }
}
@media (max-width: 350px) {
  .fotoImgGallary {
    display: block;
    width: 100%;
    height: 250px;
    /* object-fit: cover; */
  }
}
