.main-first {
  padding-bottom: 45px;
  background-image: url(../../../img/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  background-attachment: fixed;
}
.main__important2 {
  background-color: white;
  padding: 10px;
  border-radius: 20px;
}
/* .main-second {
  padding-bottom: 45px;
  height: auto;
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
} */
