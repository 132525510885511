.karcher {
  position: absolute;
  top: 5px;
  left: 0px;
  max-width: 150px;
  height: auto;
  z-index: 100;
  object-fit: contain;
}

@media (max-width: 600px) {
  .karcher {
    max-width: 130px;
  }
}
@media (max-width: 370px) {
  .karcher {
    max-width: 100px;
  }
}
