.header__social-link-item:hover {
  transform: scale(0.7);
  transition: all 0.3s;
}
.header__social-link-item {
  display: flex;
  align-self: center;
  transform: scale(0.6);
  transition: all 0.3s;
}

@media (max-width: 720px) {
  .header__social-link-item {
    width: 40px;
  }
}
@media (max-width: 510px) {
  .header__social-link-item {
    width: 50px;
  }
}
@media (max-width: 360px) {
  .header__social-link-item {
    display: flex;
    align-self: center;
    transform: scale(0.5);
  }
}
