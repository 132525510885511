.header__phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-phone-item {
  padding: 15px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
}
.header-phone-item:hover {
  color: rgb(91, 118, 163);
}

.header__phone-only {
  padding: 15px 0 15px 0;
}
.header__phone-only:hover {
  color: rgb(91, 118, 163);
}

@media (max-width: 560px) {
  .header-phone-item {
    padding: 5px;
    padding-left: 5px;
    font-size: 16px;
  }
}
@media (max-width: 490px) {
  .FiPhoneCall {
    scale: 1.2;
    padding-right: 5px;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 0px;
    font-size: 18px;
  }
}
@media (max-width: 310px) {
  .header__phone-only {
    padding: 15px;
  }
  .FiPhoneCall {
    scale: 1;
    padding-right: 5px;
  }
  .header-phone-item {
    padding: 5px;
    padding-left: 0px;
    font-size: 14px;
  }
}
